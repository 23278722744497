<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  import { useDateFormat } from '@vueuse/core'
  import { useProjectsMenuStore } from '@/stores/list-item-menu'
  import { useProjectsStore } from '@/stores/projects'
  import { useProjectsBarStore } from '@/stores/floating-bars'
  import { useSnackbarStore } from '@/stores/snackbar'
  import type { TProject } from '@/types/projects'
  import DesHover from '@/components/DesHover.vue'
  import DesHoverMenu from '@/components/DesHoverMenu.vue'
  import DesButtonIcon from '@/components/button/DesButtonIcon.vue'
  import DesEditName from '@/components/DesEditName.vue'
  import DesCheckbox from '@/components/form/DesCheckbox.vue'

  const props = defineProps<{
    item: TProject,
    disableCheckboxes?: boolean,
    templateEditor?: boolean,
  }>()

  const projects = useProjectsStore()
  const bar = useProjectsBarStore()
  const snack = useSnackbarStore()
  const { open } = useProjectsMenuStore()

  const formatDate = (date: string) => useDateFormat(date, 'MMMM DD, YYYY', { locales: 'en-US' })
  const preview = (p: TProject) => window.open(`${location.origin}/projects/${p.id}/render`)
  const tile = ref<HTMLElement | null>(null)
  const checked = ref<boolean>(bar.is(props.item))

  const edit = (project: TProject) => {
    projects.goToBuilderAndEdit(project.slug_id, project.slug)
  }

  const renameProject = async (project: TProject, name: string) => {
    const oldName = project.name
    project.name = name

    const res = await projects.renameProject(project.id, project.name)
    if (res.status === 'error') {
      snack.add(res.message)
      project.name = oldName
    } else {//rename changes the slug
      project.slug = res.slug
    }
  }

  watch(
    () => bar.list.length,
    () => checked.value = bar.is(props.item)
  )

  const imageSrc = computed(() => {
    const prefix = location.origin + '/assets/images/projects/'
    const postfix = '?updated=' + encodeURI(props.item.updated_at)
    const base = props.item.cover_regular || `project-${props.item.id}.png`

    return prefix + base + postfix
  })
</script>

<template>
  <div class="flex flex-col gap-1.5" ref="tile">
    <div class="relative h-52 px-4 pt-4 bg-white-gray rounded-xl justify-center items-start inline-flex overflow-hidden cursor-pointer border"
      :class="checked ? 'border-blue-50' : 'border-transparent'">
      <div class="h-64 flex-col justify-start items-center inline-flex">
        <img
          class="rounded-md max-h-full max-w-full w-auto h-auto shadow"
          :src="imageSrc"
          loading="lazy" alt="" width="0" height="0"
        >
      </div>
      <div v-if="props.templateEditor && item?.global_template_copy_id" class="rounded-r bg-blue-90 h-icon text-blue-50 text-xs font-bold leading-none p-1 absolute left-0 top-2">
        Pro
      </div>
      <DesHover data-sortable-handler @click.stop="edit(item)">
        <DesHoverMenu>
          <DesButtonIcon @click.stop="preview(item)" icon="fa-light fa-eye" />
          <DesButtonIcon @click.stop="tile && open(item, tile)" icon="fa-light fa-ellipsis" />
        </DesHoverMenu>
        <DesCheckbox v-model="checked" @click.stop.prevent="bar.toggle(item)" v-if="props.disableCheckboxes !== true"/>
      </DesHover>
      <DesHover @click.stop.prevent="bar.toggle(item)" v-if="bar.list.length > 0" locked>
        <DesCheckbox v-model="checked" v-if="props.disableCheckboxes !== true"/>
      </DesHover>
    </div>
    <div>
      <DesEditName :name="item.name" @edit="(newValue: string) => renameProject(item, newValue)" />
      <div class="mt-1 text-black-40 text-xs leading-none">{{ item.project_page_updated_at ? formatDate(item.project_page_updated_at).value : ''}}</div>
    </div>
  </div>
</template>
