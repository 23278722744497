import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { defineStore } from 'pinia'
import { useTimeoutFn, useStorage } from '@vueuse/core'
import { useUserStore } from '@/stores/user'
import { useVoiceStore } from '@/stores/voice'
import { useSnackbarStore } from '@/stores/snackbar'
import { usePopupStore } from '@/stores/popup'
import { http } from '@/helpers/http'
import { track } from '@/helpers/mixpanelDes'
import { Speaker } from '@/types/voice'
import { Node } from '@tiptap/pm/model'
import type { TUserSortOptions } from '@/types/projects'
import type { TCombinedSearchResult } from '@/types/search'
import type {
  TAudiobookDraftResponse,
  TAudiobookCreateRes,
  TAudiobookPreview,
  TProjectAudiobook,
  TAudiobookResponse,
  TAudiobookGenerateResponse,
  TAudiobookSectionGenerateResponse,
} from '@/types/audiobooks'
import type { Editor } from '@tiptap/vue-3'

export const useAudiobooksStore = defineStore('audiobooks', () => {
  const user = useUserStore()
  const route = useRoute()
  const voices = useVoiceStore()
  const snack = useSnackbarStore()
  const popup = usePopupStore()
  const router = useRouter()

  const audiobooks = ref<TProjectAudiobook[]>([])
  const sortOptions = ref<TUserSortOptions>({})
  const draft = ref<TAudiobookDraftResponse | null>(null)
  const project = ref<TAudiobookResponse | null>(null)
  const player = new Audio()
  const playedNode = ref<Node | null>(null)
  const isPlaying = ref<boolean>(false)
  const editPanelVisible = ref(false)
  const creditsLeft = ref<number | null>(null)

  async function fetchAudiobooks(query?: string) {
    sortOptions.value = useStorage('sortOptions', {} as TUserSortOptions).value
    const data: TProjectAudiobook[] | null = await http.post<TProjectAudiobook[] | null>('/tts/projects', {
      query: query ?? '',
    })
    if (!data) {
      throw new Error(`Failed to fetch audiobooks`)
    }
    audiobooks.value = data

    sort(sortOptions.value[user.user.id]?.projects)
  }

  async function checkAudiobooks() {
    if (route.name === 'projects' && route.params.type === 'audiobooks') {
      if (audiobooks.value.some((audiobook) => audiobook.status !== 2)) {
        try {
          await fetchAudiobooks()
        } catch {
          // continue
        }
      }

      useTimeoutFn(() => checkAudiobooks(), 15e3)
    }
  }

  async function deleteAudiobook(project: TProjectAudiobook) {
    const index = audiobooks.value.findIndex((item) => {
      return project.id === item.id
    })
    if (index >= 0) {
      track('audiobooks-delete', { id: project.id })
      audiobooks.value.splice(index, 1)
      const res = await fetch(`/tts/delete_audiobook_version`, {
        method: 'POST',
        body: JSON.stringify({
          book_id: project.id,
        }),
      })
      if (!res.ok) {
        throw new Error(`Failed to delete audiobook`)
      }
    }
  }

  async function renameAudiobook(book_id: number, bookName: string) {
    track('audiobooks-rename', { id: book_id })
    const res = await fetch('/tts/rename_audiobook', {
      method: 'POST',
      body: JSON.stringify({
        book_id,
        bookName,
      }),
    })
    if (!res.ok) {
      throw new Error(`Failed to rename project`)
    }
    const data = await res.json()
    return data
  }

  async function getAudiobookPreviewUrl(audiobook: TProjectAudiobook): Promise<string>
  async function getAudiobookPreviewUrl(id: number, token: string): Promise<string>
  async function getAudiobookPreviewUrl(arg1: TProjectAudiobook | number, arg2?: string): Promise<string> {
    const firstArgNotNumber = typeof arg1 !== 'number'
    const audiobookId = firstArgNotNumber && arg1.id ? arg1.id : arg1
    const token = firstArgNotNumber && arg1.token ? arg1.token : arg2

    const res = await fetch(`/tts/preview/create_entry_token/${audiobookId}`, {
      method: 'POST',
    })
    if (!res.ok) {
      throw new Error(`Failed to rename project`)
    }

    const data = await res.json()
    const tokenToUse = token || data.token

    return `/tts/preview/${tokenToUse}/${data.id}/1/1/1`
  }

  function sort(sortBy: string) {
    switch (sortBy) {
      case 'az':
        audiobooks.value.sort((a, b) => a.name.localeCompare(b.name))
        break
      case 'za':
        audiobooks.value.sort((a, b) => b.name.localeCompare(a.name))
        break
      case 'oldest':
        audiobooks.value.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
        break
      case 'newest':
        audiobooks.value.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
        break
    }
  }

  async function preview(projectId: number): Promise<TAudiobookPreview | null> {
    track('audiobooks-preview', { id: projectId })
    return await http.post<TAudiobookPreview>('/tts/preview/create_entry_token/' + projectId)
  }

  function isInProgress(project: TProjectAudiobook | TCombinedSearchResult): boolean {
    return project?.status === 1
  }

  async function createDraft(
    content: string,
    articleTitle: string = '',
    author: string = '',
  ): Promise<TAudiobookCreateRes> {
    track('audiobooks-create-draft')
    if (!author) {
      const userStore = useUserStore()
      author = userStore.getUserFullName(userStore.user)
    }
    if (!articleTitle) {
      articleTitle = 'New Audiobook'
    }
    return await http.post('/tts/created_draft', {
      content,
      articleTitle,
      author,
    })
  }

  async function getAudiobookDraft(id: number) {
    draft.value = null
    try {
      draft.value = await http.post<TAudiobookDraftResponse>('/get_audiobook_draft', { id })
      creditsLeft.value = draft.value.creditsLeft
    } catch (error) {
      console.error(error)
    }
  }

  async function getAudiobook(draft_id: number) {
    project.value = null
    try {
      project.value = await http.post<TAudiobookResponse>('/drafts/getAudioBook', { draft_id })
    } catch (error) {
      console.error(error)
    }
  }

  function contentToDraft(html: string): string {
    return `<div
      id="tts-content-editor"
      class="tts-voice"
      data-tts-voice="${voices.mainSpeaker?.name}"
      data-tts-pitch="${voices.mainSpeaker?.pitch}"
      data-tts-speed="${voices.mainSpeaker?.speed}"
      data-tts-style="${voices.mainSpeaker?.style}"
      data-tts-name="${voices.mainSpeaker?.displayName}"
      ${voices.mainSpeaker?.customName ? `data-tts-custom-name="${voices.mainSpeaker.customName}"` : ''}
    >${html}</div>`
  }

  function draftToContent(html: string): string {
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')

    const div = doc.body.firstElementChild

    if (!div) {
      voices.mainSpeaker = voices.defaultSpeaker
      return ''
    }

    const attributes: Record<string, string> = {}
    for (const attr of div.attributes) {
      if (attr.name.startsWith('data-tts-')) {
        const key = attr.name.replace('data-tts-', '')
        attributes[key] = attr.value
      }
    }

    if (attributes.voice) {
      const { voice, style, speed, pitch, customName } = attributes
      const voiceTTS = voices.findByName(voice)

      if (!voiceTTS) {
        voices.mainSpeaker = voices.defaultSpeaker
      } else {
        voices.mainSpeaker = new Speaker(voice, ~~pitch, ~~speed, style, customName || null, voiceTTS)
      }
    }

    if (!voices.mainSpeaker) {
      voices.mainSpeaker = voices.defaultSpeaker
    }

    return div.innerHTML
  }

  function escapeXML(str: string): string {
    return str
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&apos;')
  }

  function generateParagraphSSML(node: Node): string {
    let ssml = ''

    const speaker = voices.getSpeakerFromNode(node) || voices.mainSpeaker!
    const voice = speaker.name ?? 'en-US-JennyNeural'
    const speed = `${speaker.speed ?? 0}%`
    const pitch = `${speaker.pitch ?? 0}%`
    const style = speaker.style ?? 'Default'

    let content = ''
    node.descendants((mark) => {
      if (mark.type.name === 'audiobookBreak') {
        content += generateBreakSSML(node)
      } else if (mark.text) {
        content += escapeXML(mark.text)
      }
    })

    ssml += `<voice name="${voice}">`

    if (style) {
      ssml += `<mstts:express-as style="${style}">`
    }

    ssml += `<prosody rate="${speed}" pitch="${pitch}">`

    if (node.attrs.level && node.attrs.level > 0) {
      const headerTag = `h${node.attrs.level}`
      ssml += `<${headerTag}><emphasis level="moderate">${content}</emphasis></${headerTag}>`
    } else {
      ssml += `<p>${content}</p>`
    }

    ssml += `</prosody>`

    if (style) {
      ssml += `</mstts:express-as>`
    }

    ssml += `</voice>`

    return ssml
  }

  function generateBreakSSML(node: Node): string {
    const breakValue = node.attrs['data-tts-break'] || 'medium' // default to "medium" if not provided
    return `<break strength="${breakValue}" />`
  }

  function nodesToSSML(nodes: Node[]): string {
    let ssml =
      '<speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="en-US">'

    nodes.forEach((node) => {
      if (node.type.name === 'audiobookParagraph') {
        ssml += generateParagraphSSML(node)
      } else if (node.type.name === 'audiobookBreak') {
        ssml += generateBreakSSML(node)
      }
    })

    ssml += '</speak>'

    return ssml
  }

  function contentToSSML(editor: Editor): string {
    const nodes: Node[] = []

    editor.state.doc.descendants((node: any) => {
      nodes.push(node)
    })

    return nodesToSSML(nodes)
  }

  function estimateCreditsUsage(ssml: string): number {
    const content = ssml
      .replace(/<speak[^>]*>/gi, '')
      .replace(/<\/speak>/gi, '')
      .replace(/<voice[^>]*>/gi, '')
      .replace(/<\/voice>/gi, '')
      .trim()

    return content.length
  }

  async function previewParagraph(ssml: string, draftId: number, node: Node) {
    player.pause()

    if (node.toString() === playedNode.value?.toString() && isPlaying.value) {
      isPlaying.value = false
      return
    }

    isPlaying.value = false
    playedNode.value = node

    try {
      const response = await http.post<TAudiobookSectionGenerateResponse>('/tts/sample', {
        ssml,
        draftId,
        oldHash: node.attrs.id,
      })

      if ('status' in response && response.status === 'error') {
        if (response.showOffer) {
          popup
            .confirm("You don't have enough credits. Would you like to buy more credits?", {
              labelConfirm: 'Buy credits',
            })
            .then((res) => {
              res && router.push('/profile#upgrade')
            })
        } else {
          snack.add(response.message)
        }

        return
      } else if ('url' in response) {
        player.oncanplay = () => {
          player.play()
          isPlaying.value = true
        }
        player.onended = () => {
          previewStop()
        }
        player.src = response.url
        creditsLeft.value = response.creditsLeft

        return response
      }
    } catch (error) {
      console.error(error)
      snack.add('Unable to generate audio, please try again in a moment')
    }
  }

  function previewStop() {
    player.pause()
    player.oncanplay = null
    isPlaying.value = false
  }

  async function generateAudio(
    ssml: string,
    html: string,
    draftId: number,
    voices: { language: string; voicename: string }[],
  ) {
    const res = await http.post<TAudiobookGenerateResponse>('/tts/synthesizeLong', { draftId, voices, ssml, html })
    if (res.status === 'error') {
      track('audiobooks-generate', { status: res.status, error: res.message })
    } else {
      track('audiobooks-generate', { status: res.status })
    }
    return res
  }

  function getErrorForAudioBookStatus(status: number): string {
    switch (status) {
      case 1:
      case 3: {
        return 'The audio book is still processing!'
      }
      case 4: {
        return 'There was an error processing this book!'
      }
      default: {
        return 'There is a problem with your book! Invalid status!'
      }
    }
  }

  return {
    createDraft,
    audiobooks,
    draft,
    project,
    isPlaying,
    playedNode,
    editPanelVisible,
    creditsLeft,
    isInProgress,
    preview,
    fetchAudiobooks,
    checkAudiobooks,
    deleteAudiobook,
    renameAudiobook,
    getAudiobookPreviewUrl,
    getAudiobook,
    getAudiobookDraft,
    sort,
    draftToContent,
    contentToDraft,
    contentToSSML,
    nodesToSSML,
    previewParagraph,
    previewStop,
    generateAudio,
    generateParagraphSSML,
    getErrorForAudioBookStatus,
    estimateCreditsUsage,
  }
})
